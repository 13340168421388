import Swiper from 'swiper';
import {add_swiper_class} from '@mairiedepamiers/js/tools/swiper';
import Utils from '@mairiedepamiers/js/services/utils';

export default class Slider {

	constructor() {
		Slider.hero();
		Slider.related_post();
		Slider.slider_strate_mes_services();
		Slider.slider_strate_mes_participation_citoyenne();
		Slider.slider_strate_mes_actus();
		Slider.slider_strate_mes_evenements_events_content();
		Slider.slider_strate_mes_evenements_events_thumbnail();
		Slider.slider_strate_mes_evenements_events_smartphone();
		Slider.slider_strate_mes_evenements_agendas();
	}

	/**
	 * Slider banner
	 */
	static hero() {
		let slider = '[data-id="wpiris-hero-slider"]',
			slider_wrapper = '[data-id="wpiris-hero-slider-wrapper"]',
			slider_item = '[data-id="wpiris-hero-slider-item"]';

		if ($(slider).length > 0) {

			add_swiper_class($(slider), $(slider_wrapper), $(slider_item));

			new Swiper($(slider), {
				mousewheel: {
					forceToAxis: true,
				},
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				autoplay: {
					delay: 3000,
				},
				speed: 2000,
				slidesPerView: 1,
				loop: true,
				lazy: true,
				navigation: {
					nextEl: '[data-slider-navigation="next"]',
					prevEl: '[data-slider-navigation="prev"]',
				},
			});
		}
	}

	/**
	 * Slider contenu relatif
	 */
	static related_post() {

		let slider = '[data-id="wpiris-related-post"]',
			slider_wrapper = '[data-id="wpiris-related-post-wrapper"]',
			slider_item = '[data-id="wpiris-related-post-item"]';

		if ($(slider).length > 0) {

			$(slider).each(function () {

				add_swiper_class($(slider), $(slider_wrapper), $(slider_item));

				let slides_per_view = undefined !== $(this).attr('data-slides-per-view') ? $(this).attr('data-slides-per-view') : 3,
					slides_per_view_large = undefined !== $(this).attr('data-slides-per-view-large') ? $(this).attr('data-slides-per-view-large') : 3,
					slides_per_view_medium = undefined !== $(this).attr('data-slides-per-view-medium') ? $(this).attr('data-slides-per-view-medium') : 2,
					slides_per_view_small = undefined !== $(this).attr('data-slides-per-view-small') ? $(this).attr('data-slides-per-view-small') : 2;

				new Swiper($(this), {
					slidesPerView: slides_per_view_small,
					spaceBetween: 10,
					speed: 600,
					keyboard: true,
					freeMode: true,
					watchSlidesVisibility: true,
					passiveListeners: false,
					breakpoints: {
						650: {
							slidesPerView: slides_per_view_medium,
							spaceBetween: 20,
						},
						1000: {
							slidesPerView: slides_per_view_large,
							spaceBetween: 20,
						},
						1381: {
							slidesPerView: slides_per_view,
							spaceBetween: 40,
						}
					}
				});

			});
		}
	}

	static slider_strate_mes_services() {
		const options = {
			slidesPerView: 2.5,
			spaceBetween: 20,
			lazy: true,
			navigation: {
				prevEl: '.swiper-strate-mes-services-button--prev',
				nextEl: '.swiper-strate-mes-services-button--next',
			},
		}

		if (window.matchMedia("(max-width: 650px)").matches) {
			Utils.swiperWrapper('[data-id="wpiris-strate-mes-services"]');
			new Swiper('[data-id="wpiris-strate-mes-services"]', options);
		}
	}

	static slider_strate_mes_participation_citoyenne() {
		const options = {
			slidesPerView: 1,
			spaceBetween: 10,
			lazy: true,
			navigation: {
				prevEl: '.swiper-strate-participation-citoyenne-button--prev',
				nextEl: '.swiper-strate-participation-citoyenne-button--next',
			},
			breakpoints: {
				650: {
					spaceBetween: 20,
				},
				1000: {},
				1200: {
					slidesPerView: 2,
					spaceBetween: 40,
				}
			}
		}

		Utils.swiperWrapper('[data-id="wpiris-strate-participation-citoyenne"]');
		new Swiper('[data-id="wpiris-strate-participation-citoyenne"]', options);
	}

	static slider_strate_mes_actus() {
		const options = {
			slidesPerView: 1,
			spaceBetween: 10,
			lazy: true,
			navigation: {
				prevEl: '.swiper-strate-mes-actus-button--prev',
				nextEl: '.swiper-strate-mes-actus-button--next',
			},
			breakpoints: {
				650: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 40,
				}
			}
		}

		Utils.swiperWrapper('[data-id="wpiris-strate-mes-actus"]');
		new Swiper('[data-id="wpiris-strate-mes-actus"]', options);
	}

	static slider_strate_mes_evenements_events_content() {
		const options = {
			slidesPerView: 1,
			lazy: true,
			navigation: {
				prevEl: '.swiper-strate-mes-evenements-events-button--prev',
				nextEl: '.swiper-strate-mes-evenements-events-button--next',
			},
			allowTouchMove: false,
		}

		Utils.swiperWrapper('[data-slider="wpiris-slider-event-content"]');
		new Swiper('[data-slider="wpiris-slider-event-content"]', options);
	}

	static slider_strate_mes_evenements_events_thumbnail() {
		const options = {
			slidesPerView: 1,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			lazy: true,
			navigation: {
				prevEl: '.swiper-strate-mes-evenements-events-button--prev',
				nextEl: '.swiper-strate-mes-evenements-events-button--next',
			},
			allowTouchMove: false,
		}

		Utils.swiperWrapper('[data-slider="wpiris-slider-event-thumbnail"]');
		new Swiper('[data-slider="wpiris-slider-event-thumbnail"]', options);
	}

	static slider_strate_mes_evenements_events_smartphone() {
		const options = {
			slidesPerView: 1,
			spaceBetween: 10,
			lazy: true,
			navigation: false,
		}

		Utils.swiperWrapper('[data-slider="wpiris-slider-event-smartphone"]');
		new Swiper('[data-slider="wpiris-slider-event-smartphone"]', options);
	}

	static slider_strate_mes_evenements_agendas() {
		const options = {
			slidesPerView: 1,
			spaceBetween: 10,
			lazy: true,
			navigation: false,
			breakpoints: {
				700: {
					slidesPerView: 2,
				}
			}
		}

		Utils.swiperWrapper('[data-slider="wpiris-slider-event-agenda"]');
		new Swiper('[data-slider="wpiris-slider-event-agenda"]', options);
	}
}