import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/modal';
import Modernizr from 'modernizr';
import LazyLoad from 'vanilla-lazyload';
import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import apiFetch from "@wordpress/api-fetch";

export default class Utils {

	constructor() {
		Utils.click();
		Utils.bootstrap();
		Utils.fancybox();
		Utils.searchbox();
		Utils.smooth_scroll();
		Utils.lazy();
		Utils.block_cover();
		Utils.obfuscation();
		Utils.info_live_marquee();
		Utils.sticky_navbar();
		Utils.show_more();
		Utils.burger_menu();
		Utils.display_submenu();
		Utils.initialize_instagram();
		Utils.indexes_accordions();
		Utils.generate_nav_link_anchor();
		Utils.isotope();
		Utils.scroll_reveal();
	}

	static click() {
		const trigger = '[data-trigger="wpiris-click"]';

		if (!Modernizr.touchevents) {
			let ctrl = 0;
			$(window).on('keydown', function (e) {
				if (e.which == 17 || e.which == 91) {
					ctrl = true
				}
			})
			$(window).on('keyup', function () {
				ctrl = false
			})

			$(document).on('mouseup', trigger, function (e) {

				if (e.target.tagName.toLowerCase() !== 'a') {
					if (e.which == 1 || e.which == 2) {
						let blank = false
						let url = $(this).find("a").attr("href")
						if (e.which == 2) {
							blank = true
						} // Clic molette
						if ($(this).find("a").attr("target") == "_blank") {
							blank = true
						}
						if (blank || ctrl) {
							window.open(url, '_blank')
						} else {
							window.location = url
						}
						return false
					}
				}
				return false

			})
		} else {
			$(document).on('click', trigger, function (e) {

				let blank = false
				let url = $(this).find("a").attr("href")
				if ($(this).find("a").attr("target") == "_blank") {
					blank = true
				}
				if (blank) {
					window.open(url, '_blank')
				} else {
					window.location = url
				}
				return false

			})
		}
	}

	static bootstrap() {
		if (!Modernizr.touchevents) {
			$('[data-toggle="tooltip"]').tooltip({trigger: "hover"})
		}

		$('[data-toggle="popover"]').popover();
	}

	static fancybox() {

		$('.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")').each(function () {

			const href = $(this).attr('href');

			if (href.match(/(.jpg|.jpeg|.png|.gif|.svg|.webp)$/)) {
				$(this).attr('data-fancybox', 'fancybox');
			} else {
				if (href.indexOf(Theme.server_url) === -1) {
					$(this).attr('target', '_blank');
				}
			}
		});


		let counter = 0;
		$('.hentry .wp-block-gallery').each(function () {
			counter++;
			$(this).find('a:has(>img)').attr('data-fancybox', 'wp-block-gallery-' + counter);
		});

		counter = 0;
		$('.hentry .wp-block-image').each(function () {
			counter++;
			$(this).find('a:has(>img)').attr('data-fancybox', 'wp-block-image-' + counter);
		});

		$('[data-fancybox]').fancybox({
			buttons: [
				"zoom",
				"slideShow",
				"close"
			],
			backFocus: false,
			lang: "fr",
			i18n: {
				fr: {
					CLOSE: Theme.translate.fancybox_close,
					NEXT: Theme.translate.fancybox_next,
					PREV: Theme.translate.fancybox_prev,
					ERROR: Theme.translate.fancybox_error,
					PLAY_START: Theme.translate.fancybox_start,
					PLAY_STOP: Theme.translate.fancybox_stop,
					FULL_SCREEN: Theme.translate.fancybox_full_screen,
					THUMBS: Theme.translate.fancybox_thumbs,
					DOWNLOAD: Theme.translate.fancybox_download,
					SHARE: Theme.translate.fancybox_share,
					ZOOM: Theme.translate.fancybox_zoom
				},
			},
			caption: function (instance, item) {
				let caption = $(this).closest('figure').find('figcaption').html()

				if (!caption) {
					caption = $(this).find('img').attr('title')
				}

				if (!caption) {
					caption = $(this).find('img').attr('alt')
				}

				return caption
			}
		})
	}

	static searchbox() {
		const trigger = '[data-trigger="wpiris-search-layout"]',
			close = '[data-trigger="wpiris-search-layout-close"]',
			$element = $('[data-id="wpiris-search-layout"]');

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			$element.fadeIn().find('input').focus();
		})

		$(document).on('click', close, function (e) {
			e.preventDefault();
			$element.fadeOut();
		})
	}

	static smooth_scroll() {
		const trigger = '[data-trigger="wpiris-scroll"]';

		$(document).on('click', trigger, function (e) {
			e.preventDefault();

			const target = $(this).attr("href") != '' ? $(this).attr("href") : $(this).attr("data-href"),
				duration = 1000,
				scroll_top = $(target).offset().top - (undefined !== e.currentTarget.dataset.shift ? e.currentTarget.dataset.shift : 0);

			$('html, body').animate({
				scrollTop: scroll_top
			}, {
				duration: duration,
				step: (now, fx) => {
					let real_pos = scroll_top;
					if (fx.end !== real_pos) {
						fx.end = real_pos;
					}
				}
			});
		})
	}

	static lazy() {
		const lazyLoadInstance = new LazyLoad({
			elements_selector: '[data-src], [data-bg], [data-bg-hidpi]'
		});
		window.onbeforeprint = function () {
			lazyLoadInstance.loadAll();
		};
	}

	static block_cover() {
		$('.wp-block-cover:has(.wp-block-cover__inner-container a)').attr('data-trigger', 'wpiris-click')
	}

	static obfuscation() {
		let element = "[data-obf]";
		if ($(element).length > 0) {
			if (!Modernizr.touchevents) {
				let ctrl = 0;
				$(window).on('keydown', function (e) {
					if (e.which == 17 || e.which == 91) {
						ctrl = true;
					}
				});
				$(window).on('keyup', function () {
					ctrl = false;
				});

				$(document).on('mouseup', element, function (e) {
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (e.which == 1 || e.which == 2) {
						let blank = false;
						if (e.which == 2) {
							blank = true;
						} // Clic molette
						if (target == "_blank") {
							blank = true;
						}
						if (blank || ctrl) {
							window.open(url, '_blank');
						} else {
							window.location = url;
						}
						return false;
					}

					return false;

				});
			} else {
				$(document).on('click', element, function (e) {
					let blank = false;
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (target == "_blank") {
						blank = true;
					}
					if (blank) {
						window.open(url, '_blank');
					} else {
						window.location = url;
					}
					return false;
				});
			}
		}
	}

	static info_live_close() {
		const $bodyHasInfoLive = $('body.has-infolive'),
			$infoLive = $('.info-live'),
			$infoLiveCloseBtn = $('.info-live__close');

		$infoLiveCloseBtn.on('click', function () {
			$bodyHasInfoLive.removeClass('has-infolive');
			$infoLive.fadeOut(200);
		})
	}

	static info_live_marquee() {
		// Marquee info live message
		const $infoLiveMessages = $('.info-live__messages'),
			$infoLiveMessagesItems = $('.info-live__messages__items');
		let infoLiveDelay = 20;
		let infoLiveSpeed = 2;
		let infoLiveTimer = null;

		if ($infoLiveMessages.length) {
			checkSlideable();

			$(window).on('resize', function () {
				clearTimeout(infoLiveTimer);
				checkSlideable();
			});
		}

		$infoLiveMessages.on('mouseenter', function () {
			clearTimeout(infoLiveTimer);
		});

		$infoLiveMessages.on('mouseleave', function () {
			if ($infoLiveMessagesItems.outerWidth() > $infoLiveMessages.outerWidth()) {
				scrollInfoLive();
			} else {
				clearTimeout(infoLiveTimer);
				$infoLiveMessagesItems.css({
					left: '10px'
				});
			}
		});

		function checkSlideable() {
			// $infoLiveMessagesItems.css({
			// 	minWidth: $($infoLiveMessagesItems).outerWidth() * 2
			// });

			if ($infoLiveMessagesItems.outerWidth() > $infoLiveMessages.outerWidth()) {
				$infoLiveMessagesItems.css({
					left: $infoLiveMessages.outerWidth()
				});

				scrollInfoLive();
			} else {
				clearTimeout(infoLiveTimer);
				$infoLiveMessagesItems.css({
					left: '10px'
				});
			}
		}

		function scrollInfoLive() {

			if ($infoLiveMessagesItems.position().left > -($infoLiveMessagesItems.outerWidth())) {
				$infoLiveMessagesItems.css({
					left: $infoLiveMessagesItems.position().left - infoLiveSpeed
				});
			} else {
				$infoLiveMessagesItems.css({
					left: $infoLiveMessages.outerWidth()
				});
			}

			infoLiveTimer = setTimeout(function () {
				scrollInfoLive()
			}, infoLiveDelay);
		}
	}

	static sticky_navbar() {

		let last_scroll = 0,
			direction = null;
		const $element = $('.navbar'),
			trigger_height = $element.outerHeight();

		// On scroll
		$(window).on('scroll', function () {

			const scroll = $(window).scrollTop();

			// Determine witch direction
			if (scroll > last_scroll) {
				direction = 'bottom';
				last_scroll = scroll;
			} else {
				direction = 'top';
				last_scroll = scroll;
			}

			// Set sticky when scroll up
			if ((direction === "bottom") && (scroll > trigger_height)) {
				$element.removeClass('is-sticky');
				$element.addClass('is-hidden');
			} else {
				$element.addClass('is-sticky');
				$element.removeClass('is-hidden');
			}

			// Remove class if scroll top
			if (scroll === 0) {
				$element.removeClass('is-sticky');
			}
		});
	}

	static show_more() {
		let trigger = "[data-trigger='wpiris-show-more']";

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			let attr = $(this).attr('href');
			$(attr).toggleClass('show');
			$(this).hide();
		});
	}

	static burger_menu() {
		const $burgerBtn = $('[data-burger-btn]');
		const $mainnavCloseBtn = $('[data-submenu-close-btn]');

		$burgerBtn.on('click', function () {
			$(this).toggleClass("is-open");
			$('body').toggleClass("menu-is-open");
			$('.mainnavItem:first-child').addClass('is-active');
		})

		$mainnavCloseBtn.on('click', function () {
			$(this).toggleClass("is-open");
			$('body').toggleClass("menu-is-open");
			$('.mainnavItem.is-active').removeClass('is-active');
		})
	}

	static display_submenu() {
		const $mainnavItems = $('[data-id="wpiris-menu-item"]');

		$mainnavItems.on("mouseover", function () {
			$('.mainnavItem.is-active').removeClass('is-active');
			$(this).addClass('is-active');
		})

		const $moreMenuBtns = $('[data-more-menu]');
		const $submenuHeaderReturnBtns = $('[data-trigger="wpiris-menu-back"]');

		$moreMenuBtns.on('click', function () {
			$('.submenu.is-active').removeClass('is-active');
			$(this).siblings('.submenu').addClass('is-active');
		})

		$submenuHeaderReturnBtns.on('click', function () {
			$('.submenu.is-active').removeClass('is-active');
		})
	}

	static swiperWrapper(selector, classesModifier = "") {
		$(`${selector} > *`)
			.wrapAll('<div class="swiper-wrapper"></div>')
			.addClass(`swiper-slide ${classesModifier}`);
	}

	static initialize_instagram() {
		const container = '[data-id="social-wall"]';
		if ($(container).length > 0) {
			apiFetch({
				path: Theme.iris_sso_instagram_url + '?limit=4',
				method: 'post'
			}).then((result) => {
				$(container).html(result.render);
				Utils.lazy()
			}).catch(() => {
				$(container).html(__("Désolé, une erreur est survenue pendant le chargement...", Customer.domain));
			});
		}
	}

	static indexes_accordions() {

		$('.indexesList').each(function () {
			let opened = null;

			$(this).find('.indexesItem').each(function () {
				let item = $(this);

				item.find('.indexesItemHeader').click(function () {

					if (item.hasClass('is-active')) {
						item.removeClass('is-active');
						item.find('.indexesItemContent').slideUp();
					} else {
						item.addClass('is-active');
						item.find('.indexesItemContent').slideDown();
						opened = item;
					}
				});
			});
		});
	}

	static generate_nav_link_anchor() {
		const $blockAnchors = $('[data-js="wpiris-nav-links"]'),
			$anchorItems = $('[data-js-anchor]');
		let htmlAnchorList = ""

		if ($blockAnchors.length > 0 && $anchorItems.length > 0) {
			htmlAnchorList += "<ul>";
			$anchorItems.each(function (index, anchor) {
				let anchorText = $(anchor).text();
				let anchorID = $(anchor).attr('id');
				htmlAnchorList += `<li><a href="#${anchorID}">${anchorText}</a></li>`;
				console.log(anchorID);
			})
			htmlAnchorList += "</ul>";
		}

		$blockAnchors.html(htmlAnchorList);
	}

	static isotope() {

		const grid = document.querySelector('[data-isotope-grid]'),
			$grid = $('[data-isotope-grid]'),
			$filters = $('[data-isotope-filters]'),
			current_class = 'current';

		if ($grid.length > 0) {

			// Init isotop
			const isotope = new Isotope(grid, {
				itemSelector: '[data-isotope-item]',
				masonry: {
					columnWidth: '[data-isotope-grid-sizer]',
					gutter: '[data-isotope-gutter-sizer]',
				},
			});

			// Filters
			if ($filters.length > 0) {

				// Filter items on button click
				$filters.on('click', 'a', function (e) {
					e.preventDefault();
					let filter_value = $(this).attr('data-isotope-filter');
					isotope.arrange({filter: filter_value});
				});

				// Change class on click
				$filters.each(function (i, button_group) {
					let $button_group = $(button_group);
					$button_group.on('click', 'a', function () {
						$button_group.find('.' + current_class).removeClass(current_class);
						$(this).addClass(current_class);
					});
				});
			}
		}
	}


	static scroll_reveal() {
		const ratio = 0.1;
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1
		}

		const handleIntersect = function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.intersectionRatio >= ratio) {
					entry.target.classList.remove('reveal');
					observer.unobserve(entry.target);
				}
			})
		}

		document.documentElement.classList.add('reveal-loaded');
		if(!window.matchMedia("(max-width: 1000px)").matches) {
			const observer = new IntersectionObserver(handleIntersect, options);
			document.querySelectorAll('.reveal').forEach(function (el) {
				observer.observe(el);
			})
		} else {
			for (let i = 1; i <= 10; i++ ) {
				document.querySelectorAll(`.reveal-${i}`).forEach(function(el) {
					el.classList.remove(`reveal-${i}`);
				});
			}
		}
	}
}
