import '@mairiedepamiers/scss/app-front-page.scss';
import Utils from '@mairiedepamiers/js/services/utils';
import Slider from '@mairiedepamiers/js/services/slider';

document.addEventListener("DOMContentLoaded", function () {

	// Utils
	Utils.click();
	Utils.bootstrap();
	Utils.searchbox();
	Utils.smooth_scroll();
	Utils.lazy();
	Utils.obfuscation();
	Utils.sticky_navbar();
	// Utils.js_list_focus();

	//
	Utils.burger_menu();
	Utils.display_submenu();


	// Slider
	Slider.hero();
	Slider.slider_strate_mes_services();
	Slider.slider_strate_mes_participation_citoyenne();
	Slider.slider_strate_mes_actus();
	Slider.slider_strate_mes_evenements_events_content();
	Slider.slider_strate_mes_evenements_events_thumbnail();
	Slider.slider_strate_mes_evenements_events_smartphone();
	Slider.slider_strate_mes_evenements_agendas();

	// Specific
	Utils.info_live_marquee();
	Utils.info_live_close();
	Utils.initialize_instagram();
	Utils.scroll_reveal();
})
